import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer({ isFixed }) {
  return (
    <Box 
      component="footer" 
      sx={{ 
        backgroundColor: 'primary.main', 
        color: 'white', 
        padding: 2, 
        textAlign: 'center', 
        width: '100%',
        position: isFixed ? 'fixed' : 'relative',  // Kondisional antara fixed atau relative
        bottom: isFixed ? 0 : 'auto',              // Jika fixed, tetap di bawah layar
      }}
    >
      <Typography variant="body2">
        © 2024 Informatics Study Club - Universitas Pamulang.
      </Typography>
    </Box>
  );
}

export default Footer;
