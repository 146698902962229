import React, { useState } from 'react';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel, CircularProgress } from '@mui/material';

function LihatDokumentasi() {
    const [peminatan, setPeminatan] = useState('Data Science');
    const [loading, setLoading] = useState(true); // Add a loading state

    const folderIds = {
        'Data Science': '1j8DRCft6t3iMUGP3gjsO7dlh_DNfjPBZ',
        'Website Development': '1Xrhnu9LzEfDq0_a6J-iaW7Q14sIygm4G',
        'UI/UX Design': '1uuehw3SHdlwaQmRwIYVchWZtl1E3emEg'
    };

    const handlePeminatanChange = (event) => {
        setPeminatan(event.target.value);
        setLoading(true); // Set loading to true when peminatan changes
    };

    const folderId = folderIds[peminatan];
    const folderUrl = `https://drive.google.com/embeddedfolderview?id=${folderId}#grid`;

    const handleIframeLoad = () => {
        setLoading(false); // Hide the loading animation when the iframe content has loaded
    };

    return (
        <Box sx={{ marginTop: '20px', paddingLeft: '20px', paddingRight: '20px' }}>
            <FormControl 
                variant="outlined" 
                fullWidth 
                sx={{ 
                    marginBottom: '20px', 
                    minWidth: 240,
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    '& .MuiInputLabel-root': {
                        fontSize: '1rem',
                        color: '#555',
                    },
                    '& .MuiSelect-select': {
                        padding: '10px 14px',
                    },
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                    },
                    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#007bff',
                    },
                }}
            >
                <InputLabel>Pilih Peminatan</InputLabel>
                <Select
                    value={peminatan}
                    onChange={handlePeminatanChange}
                    label="Pilih Peminatan"
                >
                    <MenuItem value="Data Science">Data Science</MenuItem>
                    <MenuItem value="Website Development">Website Development</MenuItem>
                    <MenuItem value="UI/UX Design">UI/UX Design</MenuItem>
                </Select>
            </FormControl>
            
            <Typography variant="h6" gutterBottom sx={{ marginBottom: '20px' }}>
                Dokumentasi {peminatan}
            </Typography>

            <div style={{ width: '100%', height: '600px', overflow: 'hidden', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', position: 'relative' }}>
                {loading && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            zIndex: 1,
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                <iframe 
                    src={folderUrl} 
                    style={{ width: '100%', height: '100%', border: 'none' }} 
                    title={`Dokumentasi Folder ${peminatan}`}
                    onLoad={handleIframeLoad} // Listen for iframe load event
                ></iframe>
            </div>
        </Box>
    );
}

export default LihatDokumentasi;
