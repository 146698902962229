import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Typography, CircularProgress, IconButton, Dialog, DialogTitle, DialogContent,
  DialogActions, Button, Snackbar, Alert, TextField, InputAdornment, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useAuth } from './AuthContext';

// Styled components for the header
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '10px',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function MahasiswaList() {
  const [mahasiswaList, setMahasiswaList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMahasiswa, setSelectedMahasiswa] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const { user } = useAuth(); // Mengambil informasi pengguna dari useAuth

  useEffect(() => {
    const fetchMahasiswa = async () => {
      try {
        const response = await axios.get('https://www.isc-unpam.my.id/api/mahasiswa');
        setMahasiswaList(response.data);
      } catch (error) {
        setError('Error fetching mahasiswa list');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchMahasiswa();
  }, []);

  const handleOpenDialog = (mahasiswa) => {
    setSelectedMahasiswa(mahasiswa);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedMahasiswa(null);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`https://www.isc-unpam.my.id/api/mahasiswa/${selectedMahasiswa.id_isc}`);
      if (response.status === 200) {
        setMahasiswaList(mahasiswaList.filter((mahasiswa) => mahasiswa.id_isc !== selectedMahasiswa.id_isc));
        setSnackbarMessage(`Mahasiswa ${selectedMahasiswa.nama_mahasiswa} dengan ID ${selectedMahasiswa.id_isc} berhasil dihapus`);
        setSnackbarOpen(true);
      } else {
        console.error('Failed to delete:', response.status);
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Error deleting mahasiswa:', error.response || error.message);
      setError('Error deleting mahasiswa');
    }
  };

  const filteredMahasiswaList = mahasiswaList.filter((mahasiswa) =>
    mahasiswa.nama_mahasiswa.toLowerCase().includes(searchTerm.toLowerCase()) ||
    mahasiswa.id_isc.toLowerCase().includes(searchTerm.toLowerCase()) ||
    mahasiswa.nim.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error" align="center">{error}</Typography>;
  }

  return (
    <TableContainer component={Paper} sx={{ width: '100%', maxWidth: '100vw', overflowX: 'auto', padding: '0', margin: '0 auto', boxSizing: 'border-box' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} sx={{ padding: '10px 20px', maxWidth: '100%', overflow: 'hidden' }}>
        <Typography variant="h5">Daftar Mahasiswa</Typography>
        <TextField
          variant="outlined"
          placeholder="Cari mahasiswa..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton onClick={handleClearSearch}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ width: '100%', maxWidth: '300px', marginRight: '10px' }}
        />
      </Box>

      <Table sx={{ minWidth: 650 }}> {/* Memastikan tabel bisa scroll jika tidak muat */}
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>ID ISC</StyledTableCell>
            <StyledTableCell>Nama Mahasiswa</StyledTableCell>
            <StyledTableCell>NIM</StyledTableCell>
            <StyledTableCell>Peminatan</StyledTableCell>
            {user && user.role === 'admin' && <StyledTableCell>Aksi</StyledTableCell>}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {filteredMahasiswaList.length > 0 ? (
            filteredMahasiswaList.map((mahasiswa, index) => (
              <StyledTableRow key={index}>
                <TableCell align="center">{mahasiswa.id_isc}</TableCell>
                <TableCell align="center">{mahasiswa.nama_mahasiswa}</TableCell>
                <TableCell align="center">{mahasiswa.nim}</TableCell>
                <TableCell align="center">{mahasiswa.peminatan}</TableCell>
                {user && user.role === 'admin' && (
                  <TableCell align="center">
                    <IconButton onClick={() => handleOpenDialog(mahasiswa)} color="error">
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                )}
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <TableCell colSpan={user && user.role === 'admin' ? 5 : 4} align="center">
                Tidak ada data mahasiswa.
              </TableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>

      {/* Dialog konfirmasi */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Konfirmasi Penghapusan</DialogTitle>
        <DialogContent>
          <Typography>
            Apakah Anda yakin ingin menghapus mahasiswa {selectedMahasiswa?.nama_mahasiswa} (ID ISC: {selectedMahasiswa?.id_isc})?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Batal
          </Button>
          <Button onClick={handleDelete} color="error">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </TableContainer>
  );
}

export default MahasiswaList;
