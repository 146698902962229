import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, Typography, Alert, MenuItem, Select, InputLabel, FormControl, CircularProgress, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

function BuatDokumentasi() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [peminatan, setPeminatan] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]); // Gabungkan file baru dengan file yang sudah ada
    setMessage('');
    setError('');
  };

  const handleFileRemove = (index) => {
    const newFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(newFiles);
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      setError('pilih file yang akan di upload.');
      return;
    }

    if (!peminatan) {
      setError('peminatan belum dipilih.');
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('fotos[]', file);
    });
    formData.append('peminatan', peminatan);

    try {
      setLoading(true); // Mulai loading saat upload dimulai
      await axios.post('https://www.isc-unpam.my.id/api/upload-foto', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('File berhasil di upload');
      setError('');
      setSelectedFiles([]); // Reset file setelah upload berhasil
    } catch (error) {
      setError(`Error uploading files: ${error.response?.data || error.message}`);
      setMessage('');
    } finally {
      setLoading(false); // Hentikan loading setelah upload selesai
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', textAlign: 'center', padding: '20px', border: '1px dashed grey', borderRadius: '8px' }}>
      <Typography variant="h6" gutterBottom>Upload Files</Typography>

      <FormControl fullWidth sx={{ marginBottom: '16px' }}>
        <InputLabel>Pilih Peminatan</InputLabel>
        <Select
          value={peminatan}
          onChange={(e) => setPeminatan(e.target.value)}
        >
          <MenuItem value="Data Science">Data Science</MenuItem>
          <MenuItem value="Website Development">Website Development</MenuItem>
          <MenuItem value="UI/UX Design">UI/UX Design</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ marginBottom: '16px', padding: '10px', border: '2px dashed #007bff', borderRadius: '8px', backgroundColor: '#f0f0f0' }}>
        <Typography variant="body2" color="textSecondary" gutterBottom>Select File here</Typography>
        <input 
          type="file" 
          onChange={handleFileChange} 
          style={{ marginBottom: '16px', display: 'none' }} 
          id="file-upload"
          multiple
        />
        <label htmlFor="file-upload">
          <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
            Choose File
          </Button>
        </label>
        <Typography variant="body2" color="textSecondary">Files Supported: JPG, PNG, GIF</Typography>
      </Box>

      {selectedFiles.length > 0 && (
        <Box sx={{ marginBottom: '16px' }}>
          {selectedFiles.map((file, index) => (
            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px', borderBottom: '1px solid #ddd', paddingBottom: '4px' }}>
              <Typography variant="body2">{file.name}</Typography>
              <IconButton onClick={() => handleFileRemove(index)} size="small" color="error">
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}

      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleUpload}
        sx={{ marginBottom: '16px' }}
        disabled={loading} // Disable button saat loading
      >
        {loading ? <CircularProgress size={24} /> : 'Upload'}
      </Button>

      {message && <Alert severity="success">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
    </Box>
  );
}

export default BuatDokumentasi;
