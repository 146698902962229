import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from '@mui/material/styles';

// Styling untuk tabel
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '8px',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function AbsenTable({ peminatan }) {
  const [absenData, setAbsenData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from your backend
        const mahasiswaResponse = await axios.get('https://www.isc-unpam.my.id/api/mahasiswa');
        const filteredMahasiswa = mahasiswaResponse.data.filter(mahasiswa => mahasiswa.peminatan === peminatan);

        const absensiResponse = await axios.get('https://www.isc-unpam.my.id/api/absen');
        const absensiData = absensiResponse.data || []; 

        const groupedData = absensiData.reduce((acc, curr) => {
          const key = `${curr.nama_mahasiswa}-${curr.peminatan}`;
          if (!acc[key]) {
            acc[key] = { nama_mahasiswa: curr.nama_mahasiswa, peminatan: curr.peminatan };
          }
          acc[key][`pertemuan${curr.pertemuan}`] = curr.status;
          return acc;
        }, {});

        // Gabungkan data absensi dengan mahasiswa dan urutkan berdasarkan nama_mahasiswa
        const combinedData = filteredMahasiswa
          .map(mahasiswa => {
            const key = `${mahasiswa.nama_mahasiswa}-${mahasiswa.peminatan}`;
            return groupedData[key] || { nama_mahasiswa: mahasiswa.nama_mahasiswa, peminatan: mahasiswa.peminatan };
          })
          .sort((a, b) => a.nama_mahasiswa.localeCompare(b.nama_mahasiswa)); // Mengurutkan berdasarkan nama_mahasiswa

        setAbsenData(combinedData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching absen data', err);
        setError('Failed to load data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, [peminatan]);

  const renderStatusIcon = (status) => {
    if (status === 'Hadir') {
      return <CheckCircleIcon style={{ color: 'green' }} />;
    } else if (status === 'Tidak Hadir') {
      return <CancelIcon style={{ color: 'red' }} />;
    } else {
      return <RemoveIcon style={{ color: 'grey' }} />;
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error" align="center" mt={5}>{error}</Typography>;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom align="center">
       {peminatan}
      </Typography>
      <Typography variant="body2" gutterBottom align="center">
        Presensi peminatan {peminatan}.
      </Typography>
      <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Nama Mahasiswa</StyledTableCell>
              {[...Array(14)].map((_, i) => (
                <StyledTableCell key={i}>Pertemuan {i + 1}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {absenData.map((absen, index) => (
              <StyledTableRow key={index}>
                <TableCell>{absen.nama_mahasiswa}</TableCell>
                {[...Array(14)].map((_, i) => (
                  <TableCell key={i} align="center">
                    {renderStatusIcon(absen[`pertemuan${i + 1}`])}
                  </TableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default AbsenTable;
