import React, { useState, useEffect } from 'react';
import Quagga from 'quagga';
import { Paper, Box, Typography, IconButton, Fade, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
  position: 'relative',
  borderRadius: '15px',
  backgroundColor: theme.palette.grey[200],
  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
}));

const ScannerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh',
  backgroundColor: theme.palette.background.default,
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  animation: 'zoomIn 0.5s ease-in-out',
  '@keyframes zoomIn': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
}));

function BarcodeScanner() {
  const navigate = useNavigate();
  const [scanResult, setScanResult] = useState('');
  const [status, setStatus] = useState(null); // 'success' or 'error'
  const [studentName, setStudentName] = useState(''); // State for storing the student name
  const [isProcessing, setIsProcessing] = useState(false); // New flag to prevent multiple posts
  let timeoutId;

  useEffect(() => {
    startScanner();
    return () => {
      Quagga.offDetected(handleDetected); // Remove event listener on component unmount
    };
  }, []);

  const startScanner = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Browser ini tidak mendukung akses kamera.");
      return;
    }

    Quagga.init({
      inputStream: {
        type: 'LiveStream',
        target: document.querySelector('#interactive'),
        constraints: {
          width: 640,
          height: 480,
          facingMode: 'environment', // pastikan kamera belakang digunakan
        },
      },
      decoder: {
        readers: ['code_128_reader', 'ean_reader', 'ean_8_reader', 'code_39_reader', 'upc_reader', 'upc_e_reader'],
      },
      locate: true,
    }, (err) => {
      if (err) {
        console.error('Error initializing Quagga:', err);
        alert('Terjadi kesalahan saat menginisialisasi scanner.');
        return;
      }
      Quagga.start();
    });

    Quagga.onDetected(handleDetected);
  };

  const handleDetected = (result) => {
    if (isProcessing) return; // Skip processing if already handling a scan
  
    setIsProcessing(true); // Set processing flag to true to prevent duplicate handling
    Quagga.offDetected(handleDetected); // Stop listening for further detections immediately
  
    if (result && result.codeResult) {
      const code = result.codeResult.code;
      setScanResult(code);
      Quagga.stop();
      console.log("Barcode detected: ", code);
  
      // Clear any previous timeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
  
      timeoutId = setTimeout(() => {
        axios.post('https://www.isc-unpam.my.id/api/absen', {
          id_isc: code,
          status: 'Hadir',
        })
        .then((response) => {
          console.log("Response received: ", response.data);
          setStudentName(response.data.nama_mahasiswa); // Set student name from response
          setStatus('success'); // Set status to success
          console.log('Request sent to backend: ', code);
  
          setTimeout(() => {
            setStatus(null); // Reset status after showing success message
            setIsProcessing(false); // Reset processing flag
            Quagga.onDetected(handleDetected); // Re-attach listener after processing is done
            startScanner(); // Restart the scanner
          }, 2000); // Delay before restarting scanner
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            console.log("ID not found.");
            setStatus('error'); // Set status to error if ID not found
  
            setTimeout(() => {
              setStatus(null); // Reset status after showing error message
              setIsProcessing(false); // Reset processing flag
              Quagga.onDetected(handleDetected); // Re-attach listener after processing is done
              startScanner(); // Restart the scanner
            }, 2000); // Delay before restarting scanner
          } else if (error.response && error.response.status === 500) {
            console.error('Server error, please check the backend:', error.response.data);
          } else {
            console.error('Terjadi kesalahan saat menyimpan absen', error);
          }
          setIsProcessing(false); // Reset processing flag on error
        });
      }, 500); // 500ms delay to prevent duplicate calls
    }
  };

  const handleClose = () => {
    navigate('/');
    console.log('Scanner closed');
  };

  return (
    <ScannerBox>
      <StyledPaper elevation={3}>
        <IconButton style={{ position: 'absolute', top: 10, right: 10 }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" gutterBottom>
          Scan Barcode untuk Absen
        </Typography>
        <div id="interactive" className="viewport" style={{ width: '100%', height: '300px', marginBottom: '20px', borderRadius: '15px', overflow: 'hidden', border: '2px solid #ccc' }} />

        {status === null && (
          <CircularProgress color="primary" />
        )}

        {status === 'success' && (
          <Fade in={true}>
            <IconWrapper>
              <CheckCircleIcon style={{ fontSize: 80, color: 'green' }} />
              <Typography variant="h6" color="green">
                Absen {scanResult} - {studentName} berhasil
              </Typography>
            </IconWrapper>
          </Fade>
        )}

        {status === 'error' && (
          <Fade in={true}>
            <IconWrapper>
              <CancelIcon style={{ fontSize: 80, color: 'red' }} />
              <Typography variant="h6" color="red">
                {scanResult} tidak terdaftar
              </Typography>
            </IconWrapper>
          </Fade>
        )}

        <Typography variant="body1" style={{ marginTop: '20px' }}>
          {status === null ? `Hasil Scan: ${scanResult}` : ""}
        </Typography>
      </StyledPaper>
    </ScannerBox>
  );
}

export default BarcodeScanner;
