import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { TextField, Button, Box, Typography, Paper, InputAdornment, IconButton, Snackbar, Alert, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  
  const { login } = useAuth();
  const navigate = useNavigate();

  const api = axios.create({
    baseURL: 'https://www.isc-unpam.my.id', // Pastikan baseURL benar
  });

  const handleLogin = () => {
    if (!username || !password) {
      setErrorMessage('Username dan password harus diisi.');
      setOpenSnackbar(true);
      return;
    }

    setLoading(true); // Set loading state true saat login mulai
    api.post('/api/login', {
      username,
      password,
    })
      .then((response) => {
        const { username, token, role } = response.data;
        login(username, token, role);
        navigate('/');
      })
      .catch((error) => {
        setErrorMessage('Login gagal, periksa username atau password Anda.');
        setOpenSnackbar(true);
      })
      .finally(() => {
        setLoading(false); // Set loading state false setelah proses selesai
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      height="100vh" 
      bgcolor="#f4f4f4"
      p={2}
    >
      <Paper elevation={6} sx={{ padding: 4, maxWidth: 400, borderRadius: 3 }}>
        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
          <Box
            component="img"
            src="https://i.ibb.co/hY7ChC8/logo-removebg-preview.png"
            alt="Logo"
            loading="lazy"
            onError={(e) => { e.target.src = 'https://via.placeholder.com/50'; }}
            sx={{ width: 50, height: 50, marginRight: 2 }}
          />
          <Typography variant="h4" gutterBottom align="center" color="primary">
            Selamat Datang
          </Typography>
        </Box>
        <Typography variant="body1" gutterBottom align="center" color="textSecondary">
          Login untuk melakukan scan absen
        </Typography>
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <TextField
          label="Password"
          variant="outlined"
          fullWidth
          margin="normal"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button 
          variant="contained" 
          color="primary" 
          fullWidth 
          onClick={handleLogin}
          disabled={loading} // Disable tombol saat loading
          sx={{
            marginTop: 2,
            paddingY: 1.5,
            fontWeight: 'bold',
            fontSize: '16px',
            boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
        </Button>
      </Paper>

      {/* Snackbar for error messages */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Login;
