import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import MahasiswaList from './components/MahasiswaList';
import AbsenTable from './components/AbsenTable';
import Scan from './components/Scan';
import Login from './components/Login';
import MarkAbsentForRemaining from './components/MarkAbsentForRemaining';
import Home from './components/Home'; 
import AddMahasiswa from './components/addMahasiswa';
import BuatDokumentasi from './components/BuatDokumentasi'; 
import LihatDokumentasi from './components/LihatDokumentasi'; 
import Footer from './components/Footer'; 
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, Divider, ListItemIcon, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ScanIcon from '@mui/icons-material/CameraAlt';
import WarningIcon from '@mui/icons-material/Warning';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ViewListIcon from '@mui/icons-material/ViewList';
import { AuthProvider, useAuth } from './components/AuthContext';

function App() {
  const [isFooterFixed, setIsFooterFixed] = useState(true);

  // Fungsi untuk mengecek apakah sudah sampai bagian bawah
  const checkScrollPosition = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    // Cek apakah sudah scroll sampai ke bawah
    if (scrollTop + windowHeight >= documentHeight) {
      setIsFooterFixed(false); // Ganti footer menjadi relative ketika di bagian bawah
    } else {
      setIsFooterFixed(true);  // Tetap fixed jika belum di bagian bawah
    }
  };

  useEffect(() => {
    // Tambahkan event listener untuk memantau scrolling
    window.addEventListener('scroll', checkScrollPosition);

    // Bersihkan event listener ketika komponen unmount
    return () => window.removeEventListener('scroll', checkScrollPosition);
  }, []);

  return (
    <AuthProvider>
      <Router>
        {/* Gunakan Flexbox Layout untuk memastikan Footer berada di bawah */}
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          {/* Konten utama, gunakan flex: 1 agar konten mengambil sisa ruang */}
          {/* Tambahkan paddingTop untuk mengkompensasi tinggi AppBar */}
          <Box sx={{ flex: '1', paddingTop: '64px' }}> {/* Sesuaikan paddingTop sesuai tinggi AppBar */}
            <AppBar position="fixed">
              <Toolbar>
                <Navigation />
              </Toolbar>
            </AppBar>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/scan" element={<ProtectedRoute><Scan /></ProtectedRoute>} />
              <Route path="/mahasiswa-list" element={<MahasiswaList />} />
              <Route path="/absen-table/ds" element={<AbsenTable peminatan="Data Science" />} />
              <Route path="/absen-table/website" element={<AbsenTable peminatan="Website Development" />} />
              <Route path="/absen-table/uiux" element={<AbsenTable peminatan="UI/UX Design" />} />
              <Route path="/mark-absent-remaining" element={<ProtectedRoute><MarkAbsentForRemaining /></ProtectedRoute>} />
              <Route path="/add-mahasiswa" element={<ProtectedRoute><AddMahasiswa /></ProtectedRoute>} /> 
              <Route path="/buat-dokumentasi" element={<ProtectedRoute><BuatDokumentasi /></ProtectedRoute>} />
              <Route path="/lihat-dokumentasi" element={<LihatDokumentasi />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </Box>

          {/* Footer berganti antara fixed atau relative */}
          <Footer isFixed={isFooterFixed} />
        </Box>
      </Router>
    </AuthProvider>
  );
}

function Navigation() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    setLogoutDialogOpen(true);
  };

  const confirmLogout = () => {
    logout();
    setLogoutDialogOpen(false);
    navigate('/login');
  };

  const cancelLogout = () => {
    setLogoutDialogOpen(false);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Beranda" />
        </ListItem>
        <Divider />

        {user && user.role === 'admin' && (
          <>
            <ListItem button component={Link} to="/buat-dokumentasi">
              <ListItemIcon>
                <AddAPhotoIcon />
              </ListItemIcon>
              <ListItemText primary="Buat Dokumentasi" />
            </ListItem>
          </>
        )}
        <ListItem button component={Link} to="/lihat-dokumentasi">
          <ListItemIcon>
            <ViewListIcon />
          </ListItemIcon>
          <ListItemText primary="Lihat Dokumentasi" />
        </ListItem>
        <Divider />

        {user && user.role === 'admin' && (
          <>
            <ListItem button component={Link} to="/scan">
              <ListItemIcon>
                <ScanIcon />
              </ListItemIcon>
              <ListItemText primary="Scan Absen" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to="/mark-absent-remaining">
              <ListItemIcon>
                <WarningIcon />
              </ListItemIcon>
              <ListItemText primary="Tandai Tidak Hadir Sisa Mahasiswa" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to="/add-mahasiswa">
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Tambah Mahasiswa" />
            </ListItem>
          </>
        )}
        <ListItem button component={Link} to="/mahasiswa-list">
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Data Mahasiswa" />
        </ListItem>
        <Divider />

        <ListItem button component={Link} to="/absen-table/ds">
          <ListItemText primary="Absen Data Science" />
        </ListItem>
        <ListItem button component={Link} to="/absen-table/website">
          <ListItemText primary="Absen Website Development" />
        </ListItem>
        <ListItem button component={Link} to="/absen-table/uiux">
          <ListItemText primary="Absen UI / UX" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
      <Box sx={{ ml: 'auto' }}>
        {!user ? (
          <Button color="inherit" component={Link} to="/login">
            Login
          </Button>
        ) : (
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        )}
      </Box>

      <Dialog
        open={logoutDialogOpen}
        onClose={cancelLogout}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">Konfirmasi Logout</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Apakah Anda yakin ingin keluar?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelLogout} color="primary">
            Batal
          </Button>
          <Button onClick={confirmLogout} color="error" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function ProtectedRoute({ children }) {
  const { user } = useAuth();
  
  if (!user || user.role !== 'admin') {
    return <Typography>Access Denied</Typography>;
  }

  return children;
}

export default App;
